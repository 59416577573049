/*  ==========================================================================
    FOOTER V2
    ========================================================================== */

@mixin footer--v2() {
  @include footer--v1();
  // footer center
  .bn-f-center {
    background-color: $clr__ci--primary;
    color: $clr__typo--secondary;
    padding: 40px 0;
  }
  // weather teaser
  .bn-weather-teaser {
    &.bn-weather-teaser--large {
      padding: 20px 40px;
      width: 100%;
      .bn-weather-teaser__title {
        font-size: 1.6em;
        margin-bottom: 20px;
        text-transform: uppercase;
      }
      .bn-weather-teaser__forecast {
        .bn-loader {
          color: $clr__typo--secondary;
        }
        .bn-weather-table {
          text-align: center;
          width: 100%;
          .bn-weather-table__icons {
            font-size: 5.0em;
          }
          .bn-weather-table__dates,
          .bn-weather-table__temps {
            font-size: 1.6em;
          }
        }
      }
    }
  }

  // newsletter
  .bn-newsletter {
    &.bn-newsletter--large {
      .bn-newsletter__title {
        font-size: 1.6em;
        margin-bottom: 20px;
        text-transform: uppercase;
      }
      .bn-newsletter__form {
        .bn-loader {
          color: $clr__typo--secondary;
        }
      }
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // tablet
  @media #{$mq__tablet} {
    // top
    .bn-f-top {
      .bn-f-top__boxes {
        padding-top: 0;
      }
    }
  }
  // phablet
  @media #{$mq__phablet} {
    // newsletter
    .bn-weather-teaser {
      &.bn-weather-teaser--large {
        padding-left: 20px;
        padding-right: 20px;
        .bn-weather-teaser__title {
          display: none;
        }
        .bn-weather-teaser__forecast {
          width: 100%;
        }
      }
    }
  }
  // phone
  @media #{$mq__phone} {
    // newsletter
    .bn-weather-teaser {
      &.bn-weather-teaser--large {
        .bn-weather-teaser__forecast {
          .bn-weather-table {
            .bn-weather-table__icons {
              font-size: 3.5em;
            }
            .bn-weather-table__dates,
            .bn-weather-table__temps {
              font-size: 1.2em;
            }
          }
        }
      }
    }
  }
}