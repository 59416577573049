/*  ==========================================================================
    BUTTONS
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__buttons--text: $clr__ci--primary;
$module-clr__buttons--border: $clr__ci--primary;
$module-clr__buttons--bg: $clr__white;
$module-clr__buttons--text-hover: $clr__white;
$module-clr__buttons--border-hover: $clr__ci--primary;
$module-clr__buttons--bg-hover: $clr__ci--primary;

/*
 * MODULE
 */

.bn-buttons {
  > .bn-grid__row {
    > .bn-grid__col {
      text-align: center;
      > div {
        display: inline-block;
        padding: 5px;
      }
    }
  }
  .bn-button {
    @include button--custom($module-clr__buttons--bg,$module-clr__buttons--border,$module-clr__buttons--text,$module-clr__buttons--bg-hover,$module-clr__buttons--border-hover,$module-clr__buttons--text-hover);
  }
}

// Newsletter registration button - important is necessary cause of style tags which are using important
#bn_newsletter_v2 .bn-form__button {
  //ToDo remove transparent
  background-color: transparent !important;
  border-color: $clr__white !important;
  color: $clr__white !important;
  line-height: 18px;
  letter-spacing: 2.5px;
  &:hover {
      background-color: $clr__white !important;
      border-color: $clr__white !important;
      color: $clr__ci--primary !important;
  }
}

// This is the enquirybutton on the main page at the bottom - to use id is necessary ...
#bn_quickrequest_horizontal .bn-form__button {
  color: $clr__white !important;
  &:hover {
    color: $clr__white !important;
  }
}

// This one is for the room enquiry hover effect on button
#bn_request .bn-form__button-container .bn-form__button:hover {
  background-color: $clr__getting-there !important;
  border-color: $clr__getting-there !important;
}

//This one is for the offers detail page to style the button of confirm booking
div[id*="bn_request"] .bn-form__button-container .bn-form__button span {
  color: white;
}

//ToDo remove transparent
body .bn-offers-teaser .bn-offers-teaser__holder .bn-offer-box-v2 .bn-offer-box-v2__right .bn-offer-box-v2__button .bn-button {
  background-color: transparent;
  color: $clr__white;
  border-color: $clr__white;
  &:hover {
    background-color: $clr__white;
    color: $clr__ci--primary;
    border-color: $clr__white;
  }
}

/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// phablet
@media #{$mq__phablet} {
  .bn-buttons {
    > .bn-grid__row {
      > .bn-grid__col {
        > div {
          display: block;
          padding: 10px 0 0 0;
        }
      }
    }
  }
}