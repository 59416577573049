/*  ==========================================================================
    TEXT
    ========================================================================== */

/*
 * COLORS
 */


/*
 * MODULE
 */

.bn-video {
  .video {
    position: relative;
    padding-top: 56.5%;
  }

  iframe, video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  &[data-portrait="1"] {
    .video {
      padding-top: 0;
      max-width: 400px;
      margin: 0 auto;
    }
    video {
      position: static;
    }
  }
}